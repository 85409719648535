import { useIsAuthenticated } from "@azure/msal-react"
import logo from "../../assets/logo.png"
import Searchbar from "../Searchbar/Searchbar"
import { SignInButton } from "../SignInButton/SignInButton"
import { SignOutButton } from "../SignOutButton/SignOutButton"
import classes from "./Navbar.module.css"

const Navbar = () => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <nav className={classes.nav}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.rightSide}>
        <Searchbar />
        {/* {isAuthenticated ? <SignOutButton /> : <SignInButton />} */}
        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      </div>
    </nav>
  )
}

export default Navbar
