import classes from "./CampaignColumn.module.css"
import Campaign from "../Campaign/Campaign"

const CampaignColumn = ({ campaigns, title, type }) => {
  return (
    <div className={classes.column}>
      <p className={classes.title}>{title}</p>
      {campaigns &&
        campaigns.map((campaign, i) => (
          <Campaign campaign={campaign} i={i} header={title} type={type} />
        ))}
    </div>
  )
}

export default CampaignColumn
