export const sendNotificationEmail = async (
  campaignName,
  userName,
  newPriority,
  newProfile
) => {
  await fetch(
    `http://localhost:5000/campaigns/outbound_priority_email/${campaignName}/${userName}/${newPriority}/${newProfile}`
  )
}
