import { useContext } from "react"
import classes from "./Campaign.module.css"
import { useIsAuthenticated } from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"
import { MainContext } from "../../contexts/MainContext"

const Campaign = ({ campaign, i, header, type }) => {
  const { handleSwitch, campaigns } = useContext(MainContext)
  const { accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  // userName, campaign, type, i
  const changePriority = i => {
    if (isAuthenticated) {
      handleSwitch(
        accounts[0].name,
        campaigns[type.toLowerCase()][i],
        header,
        i
      )
    }
  }

  return (
    <div className={classes.row} key={i}>
      <p>{campaign.name}</p>
      <div className={classes.rightSideWrapper}>
        <p>
          {campaign.currentProfile.includes("1st") ? "1st Attempts" : "Repeats"}
        </p>
        <button
          onClick={() => changePriority(i)}
          className={classes.switchButton}
        >
          Switch
        </button>
      </div>
    </div>
  )
}

export default Campaign
