import { useContext, useEffect } from "react"
import CampaignColumn from "../CampaignColumn/CampaignColumn"
import Navbar from "../Navbar/Navbar"
import { MainContext } from "../../contexts/MainContext"
import { useIsAuthenticated } from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"
import classes from "./Home.module.css"

const Home = () => {
  const {
    // holaCampaigns,
    // salesCampaigns,
    campaigns,
    handleTextInput,
    handleCampaignFetch,
  } = useContext(MainContext)

  const isAuthenticated = useIsAuthenticated()

  const { accounts } = useMsal()

  useEffect(async () => {
    let id = accounts[0].localAccountId
    if (isAuthenticated) {
      await handleCampaignFetch(id)
    }
  }, [])

  try {
    return (
      <div className={classes.mainContainer}>
        <Navbar handleTextInput={handleTextInput} />
        {campaigns && (
          <div className={classes.columnWrapper}>
            <CampaignColumn
              header="Sales"
              campaigns={campaigns.sales}
              title="Sales Campaigns"
              type="Sales"
            />
            <CampaignColumn
              header="HOLA"
              campaigns={campaigns.hola}
              title="HOLA Campaigns"
              type="HOLA"
            />
          </div>
        )}
      </div>
    )
  } catch (err) {
    return <p>Loading...</p>
  }
}

export default Home
