import { sendNotificationEmail } from "./sendNotificationEmail"

export const changePriority = async (campaign, profile, priority, userName) => {
  var myHeaders = new Headers()
  myHeaders.append(
    "Authorization",
    "Basic Y29uZmlnYXBpQGRnYWF1dG8uY29tOkx1aXMxOTk4ISEh"
  )
  myHeaders.append("Content-Type", "text/plain")
  myHeaders.append("Cookie", "clientId=DBB70B472C13447C913387FD09213C76")

  var raw = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"\r\n    xmlns:ser="http://service.admin.ws.five9.com/">\r\n    <soapenv:Header/>\r\n    <soapenv:Body>\r\n        <ser:modifyOutboundCampaign>\r\n            <campaign>\r\n                <name>${campaign}</name>\r\n                <profileName>${profile}</profileName>\r\n                <mode>ADVANCED</mode>\r\n                <dialingPriority>${priority}</dialingPriority>\r\n            </campaign>\r\n        </ser:modifyOutboundCampaign>\r\n    </soapenv:Body>\r\n</soapenv:Envelope>`

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }

  fetch(
    "https://api.five9.com:443/wsadmin/v12/AdminWebService",
    requestOptions
  ).then(async _ => {
    try {
      await sendNotificationEmail(campaign, userName, priority, profile)
    } catch (err) {
      console.log(err)
    }
  })
}
