import { createContext, useState, useEffect } from "react"
import { changePriority } from "../adapters/changePriority"
import { getCampaigns } from "../adapters/getCampaigns"
import { priorities } from "../utils/priorities"
import { groupFirstAndRepeats } from "../utils/groupFirstAndRepeats"
import { useIsAuthenticated } from "@azure/msal-react"

export const MainContext = createContext()

const MainContextProvider = ({ children }) => {
  const [campaignResponse, setCampaignResponse] = useState()
  const [campaigns, setCampaigns] = useState()
  const [searchInput, setSearchInput] = useState(null)
  const [holaCampaigns, setHolaCampaigns] = useState()
  const [salesCampaigns, setSalesCampaigns] = useState()
  const [loading, setLoading] = useState(true)
  const [searchResult, setSearchResults] = useState(null)

  const isAuthenticated = useIsAuthenticated()

  const handleSwitch = async (userName, campaign, type, i) => {
    const { newProfile, newPriority } = getParameters(campaign)
    // console.log(userName)
    const response = changePriority(
      campaign.name,
      newProfile,
      newPriority,
      userName
    )

    let currentCampaigns = { ...campaigns }

    if (type.toLowerCase().includes("sales")) {
      currentCampaigns.sales[i].currentProfile = newProfile
      setCampaigns(currentCampaigns)
    }

    if (type.toLowerCase().includes("hola")) {
      currentCampaigns.hola[i].currentProfile = newProfile
      setCampaigns(currentCampaigns)
    }
  }

  const getParameters = campaign => {
    let priorityInfo = getPriorityInfo(campaign.name)
    let newCampaignInfo = { campaignName: campaign.name }
    if (campaign["currentProfile"].includes(" - 1st Attempt")) {
      newCampaignInfo.newProfile = campaign["currentProfile"].replace(
        " - 1st Attempt",
        ""
      )

      newCampaignInfo.newPriority = priorityInfo.repeats
    } else {
      priorityInfo = getPriorityInfo(campaign.name)
      newCampaignInfo.newProfile = `${campaign["currentProfile"]} - 1st Attempt`
      newCampaignInfo.newPriority = priorityInfo.firstAttempts
    }

    return newCampaignInfo
  }

  const getPriorityInfo = campaignName => {
    try {
      const campaign = priorities.filter(
        el => el["campaign"] === campaignName
      )[0]

      return campaign
    } catch (err) {
      alert(err)
    }
  }

  const handleTextInput = value => {
    setSearchInput(value)
  }

  useEffect(() => {
    handleSearchResults()
  }, [searchInput])

  const handleCampaignFetch = async () => {
    const allCampaigns = await getCampaigns()
    const campaignsSortedByPriority = groupFirstAndRepeats(allCampaigns)

    setCampaignResponse(campaignsSortedByPriority)
    setCampaigns(campaignsSortedByPriority)
  }

  const handleSearchResults = () => {
    if (searchInput) {
      let result = {}
      let matchingSalesCampaigns = campaignResponse.sales.filter(campaign =>
        campaign.name.toLowerCase().includes(searchInput.toLowerCase())
      )

      let matchingHolaCampaigns = campaignResponse.hola.filter(campaign =>
        campaign.name.toLowerCase().includes(searchInput.toLowerCase())
      )

      if (matchingSalesCampaigns.length === 0) {
        result.sales = null
      } else {
        result.sales = matchingSalesCampaigns
      }

      if (matchingHolaCampaigns.length === 0) {
        result.hola = null
      } else {
        result.hola = matchingHolaCampaigns
      }

      setCampaigns(result)
    } else setCampaigns(campaignResponse)
  }

  return (
    <MainContext.Provider
      value={{
        campaigns,
        searchInput,
        holaCampaigns,
        salesCampaigns,
        loading,
        searchInput,
        handleSwitch,
        searchInput,
        handleTextInput,
        handleCampaignFetch,
        searchResult,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export default MainContextProvider
