export const priorities = [
  {
    campaign: "API OUTBOUND TEST",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Advantage Auto Direct Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Arapahoe Kia Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Axis Motorcars Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign:
      "Cannon Chevrolet Buick Cadillac of Oxford Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign:
      "Cannon Chevrolet Buick GMC of West Point Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Chevrolet Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Chrysler Dodge Jeep Ram Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Ford of Blytheville Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Ford of Starkville Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Honda of Vicksburg Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Nissan of Blytheville Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Nissan of Greenwood Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Nissan of Jackson Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Nissan of Oxford Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Preowned of Calhoun City Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Cannon Toyota of Vicksburg Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Castriota Chevrolet Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "DeFOUW Nissan of Lafayette Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Downey Nissan Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Foster Chevrolet Cadillac Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Gettel Hyundai of Charlotte County Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Gunther Kia Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Gunther Mazda Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Gunther VW and Volvo of Daytona Beach Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Gunther VW and Volvo of Delray Beach Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Gunther VW of Fort Lauderdale Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Kia of Port Charlotte Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Koons Automotive of Culpeper Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Lithia CDJR of Calallen Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Lithia CDJR of Corpus Christi Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Nalley Brunswick Buick GMC Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Nalley Honda Brunswick Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Nissan of Duarte Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Nissan of Tustin Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Peake BMW Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Pikes Peak Acura Internet Leads Sales OBS",
    firstAttempts: 22,
    repeats: 25,
  },
  {
    campaign: "Ryan Chevrolet and Honda Internet Leads Sales OBS",
    firstAttempts: 20,
    repeats: 25,
  },
  {
    campaign: "Sayville Ford Internet Leads Sales OBS",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Wright Nissan Internet Leads Sales OBS    ",
    firstAttempts: 21,
    repeats: 25,
  },
  {
    campaign: "Hertz Car Sales Albuquerque Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Austin Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Baltimore Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Bedford Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Bonita Springs Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Charlotte Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Clearwater Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Colorado Springs Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Costa Mesa Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Crystal Lake Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Denver Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Des Plaines Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Detroit Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Fort Lauderdale Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Fresno Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Hartford Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Hayward Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Houston Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Houston South Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Indianapolis Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Inglewood Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Jacksonville Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Johnston Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Kearny Mesa Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Las Vegas Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Leesburg Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Lynn Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Memphis Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Mesquite Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Minneapolis Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Morrow Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Nashville Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales New Orleans Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Norwalk Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Oklahoma City Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Orlando Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Philadelphia Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Phoenix West Bell Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Pittsburgh Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Pleasanton Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Portland Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales R2B New York Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Richmond Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Rivergate Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Riverside Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Rockville Centre Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Roseville Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Sacramento Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Salt Lake City Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales San Antonio Northeast Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales San Diego Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Sanford Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Santa Clara Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Schaumburg Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Scottsdale Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Seattle Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Smithtown Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Springfield Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales St. Louis Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Stockton Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Stone Mountain Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Tampa Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Torrance Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Ventura Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Warminster Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Webster Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Hertz Car Sales Winston Salem Internet Leads",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Off Lease Only Bradenton Internet Leads Sales OBS",
    firstAttempts: 10,
    repeats: 12,
  },
  {
    campaign: "Off Lease Only Orlando Internet Leads Sales OBS",
    firstAttempts: 10,
    repeats: 12,
  },
]
