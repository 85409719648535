import { priorities } from "./priorities"

export const groupFirstAndRepeats = campaigns => {
  const { sales, hola } = campaigns
  const salesRepeats = sales.filter(el => !el.currentProfile.includes("1st"))

  const salesFirstAttempts = sales.filter(el =>
    el.currentProfile.includes("1st")
  )

  const groupedSales = [...salesRepeats, ...salesFirstAttempts]

  const holaRepeats = hola.filter(el => !el.currentProfile.includes("1st"))

  const holaFirstAttempts = hola.filter(el => el.currentProfile.includes("1st"))

  const groupedHola = [...holaRepeats, ...holaFirstAttempts]

  return { sales: groupedSales, hola: groupedHola }
}
