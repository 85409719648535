import { useContext } from "react"
import classes from "./Searchbar.module.css"
import { AiOutlineSearch } from "react-icons/ai"
import Form from "react-bootstrap/Form"
import { MainContext } from "../../contexts/MainContext"

const Searchbar = () => {
  const { handleTextInput } = useContext(MainContext)

  return (
    <div className={classes.container}>
      <Form.Control onChange={e => handleTextInput(e.target.value)} />
      {/* <AiOutlineSearch className={classes.searchIcon} /> */}
    </div>
  )
}

export default Searchbar
